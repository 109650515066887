import React, { useEffect, useState } from "react";
import User from "assets/images/user.svg";
import ErrorIcon from "assets/images/email.svg";
import BlackArrow from "assets/images/blackArrow.svg";
import Order from "components/Order";
import momentGerman from "utils/momentGerman";
import "styles/page-styles/Orders.scss";
import { disableScroll, enableScroll } from "utils/disablescroll";
import { useParams } from "react-router-dom";
import { useLoader } from "contexts/LoaderContext";
import { fetchOrders } from "api";
import { FormattedMessage } from "react-intl";
export default function Orders() {
  const params = useParams();
  const [clicked, setClicked] = useState(-1);
  const [email, setEmail] = useState("");
  const [orders, setOrders] = useState([]);
  const { setLoading } = useLoader();
  const { token } = params;
  useEffect(() => {
    if (clicked == -1) enableScroll();
    else disableScroll();
  }, [clicked]);

  const getOrders = async () => {
    setLoading(true);
    const data = await fetchOrders(token);
    if (data.status == "success") {
      setEmail(data.email);
      setOrders(
        data.orders
          ? data.orders.map((order) => ({
              title: order.show,
              date:
                momentGerman.unix(order.eventdate).format("Do MMMM YYYY LT ") +
                "Uhr",
              place: order.location,
              numberOfTickets: order.ticketnumber,
              price: order.ticketamount,
              id: order.id,
              currency: order.currency,
            }))
          : []
      );
    } else if (data.status == "error") {
      setOrders(null);
    }
    console.log(data);
    setLoading(false);
  };
  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row px-3">
          <h1 className="page-title">
            <FormattedMessage id="ordersscreen.title" />
          </h1>
          {orders ? (
            <>
              <div className="d-flex flex-row align-items-center py-2 pt-lg-0 justify-content-start justify-content-lg-center">
                <img src={`${process.env.REACT_APP_BASE_URL}${User}`} />
                <p className="p-0 m-0 ms-3 email-address fw-600">{email}</p>
              </div>
              <p className="fw-600 px-3 w-100 my-4 small">
                <h5 className="fw-bold fs-4 m-0 p-0">
                  <FormattedMessage id="ordersscreen.orders.title" />
                </h5>
                <FormattedMessage id="ordersscreen.orders.description" />
              </p>
              {orders.length == 0 ? (
                <div className="d-flex flex-column orders-error mb-2">
                  <div className="d-flex align-items-start mb-2">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${ErrorIcon}`}
                    />
                    <div className="ms-2 title">
                      <FormattedMessage id="ordersscreen.orders.noorders.title" />
                    </div>
                  </div>
                  <div className="description">
                    <FormattedMessage id="ordersscreen.orders.noorders.description" />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column">
                  {orders.map((order, index) => (
                    <Order
                      key={order.id}
                      {...order}
                      clicked={clicked}
                      index={index}
                      setClicked={setClicked}
                      refresh={getOrders}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex flex-column orders-error mt-5 mb-2">
                <div className="d-flex align-items-start mb-2">
                  <img src={`${process.env.REACT_APP_BASE_URL}${ErrorIcon}`} />
                  <div className="ms-2 title">
                    <FormattedMessage id="ordersscreen.error.title" />
                  </div>
                </div>
                <div className="description">
                  <FormattedMessage id="ordersscreen.error.description" />
                </div>
              </div>
              <button className="btn-white mt-3">
                <div>
                  <FormattedMessage id="ordersscreen.error.button.label" />
                </div>
                <img
                  className="ms-2"
                  src={`${process.env.REACT_APP_BASE_URL}${BlackArrow}`}
                />
              </button>
            </>
          )}
        </div>
      </div>
      {clicked >= 0 && (
        <div className="overlay" onClick={() => setClicked(-1)} />
      )}
    </>
  );
}
