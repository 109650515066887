import React from "react";
import "styles/component-styles/Radiobutton.scss";
export default function RadioButton({ onSelect, label, selected, error }) {
  return (
    <div
      className={`d-flex flex-row align-items-center pb-2 ${
        error ? "text-danger" : ""
      }`}
      onClick={onSelect}
    >
      <div className={`radio me-2 ${error ? "error" : ""}`}>
        {selected && <div className="circle" />}
      </div>
      <small className="fw-bold">{label}</small>
    </div>
  );
}
