import React, { useEffect, useRef, useState } from "react";
import Arrow from "assets/images/arrow.svg";
import DarkArrow from "assets/images/darkArrow.svg";
import CheckIcon from "assets/images/check.svg";
import ErrorIcon from "assets/images/email.svg";
import BlackArrow from "assets/images/blackArrow.svg";
import "styles/component-styles/Order.scss";
import { isElementInViewport } from "utils/isElementInView";
import RadioButton from "./RadioButton";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { exchangeTicket } from "api";
import { FormattedMessage, useIntl } from "react-intl";
export default function Order({
  title,
  date,
  place,
  numberOfTickets,
  price,
  id,
  index,
  clicked,
  setClicked,
  currency,
  refresh,
}) {
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState(false);
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const ref = useRef();
  const { token } = useParams();

  useEffect(() => {
    setAgreed(false);
    setError(false);
    setRequestStatus(null);
  }, [clicked]);
  const confirmExchange = async () => {
    setLoading(true);
    const result = await exchangeTicket(token, id);
    setRequestStatus(result.status);
    setLoading(false);
  };
  const bookTicket = async () => {
    setError(!agreed);
    if (!agreed) return;
    await confirmExchange();
  };
  const closeModal = async () => {
    setClicked(-1);
    await refresh();
  };
  return (
    <div
      ref={ref}
      className={`d-flex flex-column order flip-card ${
        clicked == index ? "clicked" : ""
      }`}
    >
      <div className="row flip-card-inner m-0 p-0">
        <div className="row col-12 flip-card-front">
          <div className="col-12 col-lg-8">
            <div className="title">{title}</div>
            <div className="time-and-place">
              {date}, {place}
            </div>
          </div>
          <div className="col-12  col-lg-4">
            <div className="price mb-3">
              {numberOfTickets}
              <FormattedMessage id="ordersscreen.orders.order.for" /> {price}
              {currency == "EUR" ? "€" : "$"}
            </div>
            <button
              onClick={() => {
                if (!isElementInViewport(ref.current)) {
                  ref.current.scrollIntoView({ behavior: "smooth" });
                }
                setClicked(index);
              }}
            >
              <div>
                <FormattedMessage id="ordersscreen.orders.order.button.label" />{" "}
              </div>
              <img src={`${process.env.REACT_APP_BASE_URL}${Arrow}`} />
            </button>
          </div>
        </div>
        <div
          className={`row col-12 flip-card-back ${
            requestStatus ? requestStatus : ""
          } `}
        >
          {loading && (
            <div className="order-loading">
              <Spinner animation="border" />
            </div>
          )}
          {requestStatus == "success" ? (
            <>
              <>
                <div className="col-12 col-lg-12">
                  <div className="d-flex flex-row mb-1 align-items-start">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${CheckIcon}`}
                    />
                    <div className="title ms-2">
                      <FormattedMessage id="ordersscreen.orders.order.success.title" />{" "}
                    </div>
                  </div>
                  <div className="time-and-place">
                    <FormattedMessage id="ordersscreen.orders.order.success.description" />
                  </div>
                </div>
                <div className="col-12 offset-lg-7 col-lg-5">
                  <button
                    {...(!agreed && { className: "disabled" })}
                    onClick={closeModal}
                  >
                    <div>
                      <FormattedMessage id="ordersscreen.orders.order.success.button.label" />{" "}
                    </div>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${BlackArrow}`}
                    />
                  </button>
                </div>
              </>
            </>
          ) : requestStatus == "error" ? (
            <>
              <div className="col-12 ">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${ErrorIcon}`}
                  className="mb-1"
                />
                <div className="time-and-place">
                  <FormattedMessage id="ordersscreen.orders.order.error.description" />
                </div>
              </div>
              <div className="col-12 offset-lg-8 col-lg-4 d-flex flex-row justify-content-lg-end">
                <button
                  {...(!agreed && { className: "disabled" })}
                  onClick={() => setClicked(-1)}
                >
                  <div>
                    <FormattedMessage id="ordersscreen.orders.order.error.button.label" />{" "}
                  </div>
                  <img src={`${process.env.REACT_APP_BASE_URL}${BlackArrow}`} />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-lg-7">
                <div className="title">{title}</div>
                <div className="time-and-place">
                  {date}, {place}
                </div>
              </div>
              <div className="col-12  col-lg-5">
                <div className="price mb-2">
                  {numberOfTickets}{" "}
                  <FormattedMessage id="ordersscreen.orders.order.for" />{" "}
                  {price}
                  {currency == "EUR" ? "€" : "$"}
                </div>
                <RadioButton
                  label={
                    <FormattedMessage
                      id="ordersscreen.orders.order.confirm.agreement.label"
                      values={{
                        AGB: (
                          <a
                            href="https://showslot.com/page/veranstaltungsbedingungen/"
                            target={"_blank"}
                            className="text-decoration-underline text-white"
                          >
                            <FormattedMessage id="footer.agb" />
                          </a>
                        ),
                      }}
                    />
                  }
                  onSelect={() => {
                    setAgreed((a) => !a);
                    setError(false);
                  }}
                  selected={agreed}
                  error={error}
                />
                <button
                  {...(!agreed && { className: "disabled" })}
                  onClick={bookTicket}
                >
                  <div>
                    <FormattedMessage id="ordersscreen.orders.order.confirm.button.label" />{" "}
                  </div>
                  <img src={agreed ? Arrow : DarkArrow} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
