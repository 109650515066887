import { Route, Routes } from "react-router-dom";
import EmailScreen from "pages/EmailScreen";
import Orders from "pages/Orders";
import "bootstrap/dist/css/bootstrap.min.css";
import { withProviders } from "utils/withProviders";
import LoaderContextProvider, { useLoader } from "contexts/LoaderContext";
import Loader from "components/Loader";
import "styles/main.scss";
import { withAppLayout } from "utils/withAppLayout";
import { IntlProvider } from "react-intl";
import german from "languages/de.json";

console.log({
  needed: german["ordersscreen.orders.order.confirm.agreement.label"],
  german,
});

function App() {
  const { loading } = useLoader();
  return (
    <IntlProvider messages={german} locale="de" defaultLocale="de">
      <Routes>
        <Route path="/exchange/" element={withAppLayout(EmailScreen)} />
        <Route path="/exchange/:token" element={withAppLayout(Orders)} />
      </Routes>
      {loading && <Loader />}
    </IntlProvider>
  );
}

export default withProviders(App, LoaderContextProvider);
