export const sendEmail = async (email) =>
  await (
    await fetch(window.api + "/exchange/initiate/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ eMailAddress: email }),
    })
  ).json();

export const fetchOrders = async (token) =>
  await (
    await fetch(window.api + "/exchange/retrieve/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ accessToken: token }),
    })
  ).json();

export const exchangeTicket = async (token, id) =>
  await (
    await fetch(window.api + "/exchange/confirm/", {
      method: "POST",
      body: JSON.stringify({
        accessToken: token,
        orderID: id,
      }),
    })
  ).json();
