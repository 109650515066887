import React, { createContext, useContext, useState } from "react";

const LoaderContext = createContext();
export const useLoader = () => useContext(LoaderContext);
export default function LoaderContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  );
}
