import React from "react";
import "styles/component-styles/Loader.scss";
import Logo from "assets/images/logo.svg";
export default function Loader() {
  return (
    <div className="loader-container">
      <img src={`${process.env.REACT_APP_BASE_URL}${Logo}`} />
    </div>
  );
}
