import React from "react";
import Logo from "assets/images/logo.svg";
import Title from "assets/images/title.svg";
import FooterImage from "assets/images/footer.svg";
import "styles/layout-styles/AppLayout.scss";
import { FormattedMessage } from "react-intl";
export default function AppLayout({ children }) {
  return (
    <div className="min-vh-100  pb-5">
      <div className="d-flex flex-row align-items-center justify-content-start header p-3 p-lg-4">
        <img src={`${process.env.REACT_APP_BASE_URL}${Logo}`} />
        <img
          src={`${process.env.REACT_APP_BASE_URL}${Title}`}
          className="d-none d-lg-block logo-title"
        />
      </div>
      <div className="min-vh-75 w-100">{children}</div>
      <div className="footer">
        <img src={`${process.env.REACT_APP_BASE_URL}${FooterImage}`} />
        <a
          target="_blank"
          href="https://showslot.com/page/impressum/"
          className="mx-2"
        >
          <FormattedMessage id="footer.impressum" />
        </a>
        <a
          target="_blank"
          href="https://showslot.com/page/veranstaltungsbedingungen/"
          className="mx-2"
        >
          <FormattedMessage id="footer.datenschutz" />
        </a>
        <a
          target="_blank"
          href="https://showslot.com/page/datenschutz/"
          className="mx-2"
        >
          <FormattedMessage id="footer.agb" />
        </a>
      </div>
    </div>
  );
}
