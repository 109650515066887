import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Arrow from "assets/images/arrow.svg";
import Email from "assets/images/email.svg";
import Check from "assets/images/check.svg";
import "styles/page-styles/EmailScreen.scss";
import { checkEmail } from "utils/helperFunctions";
import { sendEmail } from "api";
import { FormattedMessage, useIntl } from "react-intl";
export default function EmailScreen() {
  const [state, setState] = useState({
    email: "",
    isEmailValid: true,
    emailSent: false,
  });
  const intl = useIntl();

  const onSubmit = async () => {
    const isEmailValid = checkEmail(state.email);
    setState((prev) => ({ ...prev, isEmailValid, emailSent: false }));
    if (!isEmailValid) return;
    const result = await sendEmail(state.email);
    if (result.status == "success") {
      setState((prev) => ({ ...prev, emailSent: true }));
    }
  };

  const steps = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "emailscreen.steps.1.title" }),
        description: intl.formatMessage({
          id: "emailscreen.steps.1.description",
        }),
      },
      {
        title: intl.formatMessage({ id: "emailscreen.steps.2.title" }),
        description: intl.formatMessage({
          id: "emailscreen.steps.2.description",
        }),
      },
      {
        title: intl.formatMessage({ id: "emailscreen.steps.3.title" }),
        description: intl.formatMessage({
          id: "emailscreen.steps.3.description",
        }),
      },
    ],
    []
  );

  return (
    <div className="email-screen page-container">
      <h1 className="page-title">
        <FormattedMessage id="emailscreen.title" />
      </h1>
      <p className="fw-600 px-3 w-100 my-4">
        <FormattedMessage id="emailscreen.description" />
      </p>
      <div className="d-flex flex-column flex-lg-row w-100">
        <div className="d-flex flex-column flex-1">
          {steps.map((step, index) => (
            <div
              key={`Step${index}`}
              className="d-flex flex-row align-items-center px-3 w-100 step"
            >
              <div className="step-number">{index + 1}</div>
              <div className="step-content">
                <div className="step-content-title">{step.title}</div>
                <div className="step-content-description">
                  {step.description}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column flex-1">
          <div className="px-3 mt-3 mt-lg-0 ">
            {!state.isEmailValid && (
              <div className="invalid-email-container w-100 d-flex flex-column p-3 mb-3">
                <div className="d-flex align-items-center mb-2">
                  <img src={`${process.env.REACT_APP_BASE_URL}${Email}`} />
                  <div className="title">
                    <FormattedMessage id="emailscreen.invalid.title" />
                  </div>
                </div>
                <div className="description">
                  <FormattedMessage id="emailscreen.invalid.description" />
                </div>
              </div>
            )}
            {state.emailSent && (
              <div className="email-sent-container w-100 d-flex flex-column p-3 mb-3">
                <div className="d-flex align-items-center mb-2">
                  <img src={`${process.env.REACT_APP_BASE_URL}${Check}`} />
                  <div className="title">
                    <FormattedMessage id="emailscreen.sent.title" />
                  </div>
                </div>
                <div className="description">
                  <FormattedMessage id="emailscreen.sent.description" />
                </div>
              </div>
            )}
            <Form.Control
              type="email"
              placeholder={intl.formatMessage({
                id: "emailscreen.input.placeholder",
              })}
              onChange={(e) =>
                setState((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </div>
          <button onClick={onSubmit}>
            <div>
              <FormattedMessage id="emailscreen.button.label" />
            </div>
            <img src={`${process.env.REACT_APP_BASE_URL}${Arrow}`} />
          </button>
        </div>
      </div>
    </div>
  );
}
